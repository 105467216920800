<template>
    <div class="main">
        <div class="content">
            <div class="my-error">
                <h1>404!</h1>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Page404',
        computed: {}
    }
</script>

<style lang="less">
    .main {

        .content {
            display: flex;
            height: 100vh;
            align-items: center;

            .my-error {
                margin: 0 auto;

                h1 {
                    font-size: 50px;

                }
            }
        }
    }

</style>